import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { formatDate } from "../formatDate";
import { loggedInUser } from "../localStorage";
import {
  whoLetter,
  whatLetter,
  willCallLetter,
  appointmentConfirmation,
  thankYouLetter,
  infopakLetter,
  preDemoLetter,
  promotionalEmail
} from "../scriptTemplates/emailTemplates";
import {
  initialCall,
  followUpCall,
  confirmDemo,
} from "../scriptTemplates/phoneTemplates";
import addHistory from "../historyHelper";
import { increaseCount } from "../userActionHistory";

function subtractBusinessDays(date, days) {
  let count = 0;
  while (count < days) {
    date.setDate(date.getDate() - 1);
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      // Skip Sunday and Saturday
      count++;
    }
  }
  return date;
}

function getNextBusinessDay(date, days) {
  let count = 0;
  while (count < days) {
    date.setDate(date.getDate() + 1);
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      // Skip Sunday and Saturday
      count++;
    }
  }
  return date;
}

//create first task and handle change
export async function handleAssignedRepChange(e, item, refreshSuspectList) {
  const newAssignedRep = e.target.value;
  console.log("Assigned Rep:", newAssignedRep);

  axios
    .get(`https://unionapi.netlify.app/.netlify/functions/api/tasks`, {
      params: {
        union: item.union,
        localNumber: item.localNumber,
      },
    })
    .then((response) => {
      if (response.data.length > 0) {
        // Existing tasks found, update them
        const tasksToUpdate = response.data;
        const updatePromises = tasksToUpdate.map((task) => {
          return axios.put(
            `https://unionapi.netlify.app/.netlify/functions/api/tasks/${task._id}`,
            {
              assignedRep: newAssignedRep,
            },
          );
        });

        Promise.all(updatePromises)
          .then(() => {
            // Update union with new assignedRep
            return axios.put(
              `https://unionapi.netlify.app/.netlify/functions/api/unions/${item._id}`,
              {
                assignedRep: newAssignedRep,
              },
            );
          })
          .then(() => {
            addHistory({
              id: item._id,
              note: `Switch assigned rep to ${newAssignedRep}`,
              reason: ``,
            });
            refreshSuspectList();
          })
          .catch((updateError) => {
            console.error("Error updating existing tasks:", updateError);
          });
      } else {
        // No existing tasks, create tasks

        const today = new Date();

        const tasksToCreate =
          newAssignedRep !== "None"
            ? [
                {
                  _id: uuidv4(),
                  completed: false,
                  date: today.toISOString(),
                  __v: 0,
                  localNumber: item.localNumber,
                  assignedRep: newAssignedRep,
                  link: item._id,
                  contactEmail: item.primaryContact
                    ? item.primaryContact === 1
                      ? item.contactEmail
                      : item.alternateContactEmail
                    : item.email || "",
                  contactNumber: "",
                  contactPerson: "",
                  description: `The introductory call`,
                  endTime: "",
                  startTime: "",
                  title: "Make Initial Call",
                  union: item.union,
                  clientType: "Suspect",
                  script: initialCall(item, newAssignedRep),
                },
              ]
            : [];
        // Update union with new assignedRep and create tasks
        axios
          .put(
            `https://unionapi.netlify.app/.netlify/functions/api/unions/${item._id}`,
            {
              assignedRep: newAssignedRep,
              strategy: "infopak",
            },
          )
          .then(() => {
            if (item.clientType !== "Client") {
              return axios.post(
                "https://unionapi.netlify.app/.netlify/functions/api/tasks",
                tasksToCreate,
              );
            }
          })
          .then((response) => {
            console.log("New tasks created:", response.data);
            addHistory({
              id: item._id,
              note: `Suspect assigned to ${newAssignedRep}`,
              reason: ``,
            });
            refreshSuspectList();
          })
          .catch((error) => {
            console.error(
              "Error updating assignedRep or creating tasks:",
              error,
            );
          });
      }
    })
    .catch((error) => {
      console.error("Error checking for existing tasks:", error);
    });
}

export async function makeProspect({
  data,
  id,
  nextContact,
  refreshSuspectList,
}) {
  const apiUrl = `https://unionapi.netlify.app/.netlify/functions/api/unions/${id}`;
  try {
    // Fetch the current union data
    const unionData = await fetchUnionData(id);

    await axios.put(apiUrl, { ...unionData, clientType: "Prospect" });
    // Refresh the suspect list

    //get all tasks and delete
    await getAllTasksAndDelete(
      `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${data.union}&localNumber=${data.localNumber}`,
    );
    const today = new Date();
    const tasksToCreate = [
      // {
      //   _id: uuidv4(),
      //   completed: false,
      //   date: today.toISOString(),
      //   __v: 0,
      //   localNumber: data.localNumber,
      //   assignedRep: data.assignedRep,
      //   link: data._id,
      //   contactEmail:
      //     data.primaryContact && data.primaryContact === 1
      //       ? data.contactEmail || data.alternateContactEmail || data.email
      //       : data.email || "",
      //   contactNumber: "",
      //   contactPerson: "",
      //   description: `${data.contactName ? `Send Infopak Confirmation Email to ${data.contactName} (${data.contactJobTitle})` : ""}`,
      //   endTime: "",
      //   startTime: "",
      //   title: "Send Infopak Email",
      //   union: data.union,
      //   clientType: "Prospect",
      //   completable: true,
      //   propertyToUpdate: "emailsSent",
      //   script: infopakEmail(data),
      // },
      {
        _id: uuidv4(),
        completed: false,
        date: today.toISOString(),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail:
          data.primaryContact && data.primaryContact === 1
            ? data.contactEmail || data.alternateContactEmail || data.email
            : data.email || "",
        contactNumber: "",
        contactPerson: "",
        description: `${data.contactName ? `Inform promotional about infopak for ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: "Contact Promotional Regarding Infopak",
        union: data.union,
        clientType: "Prospect",
        completable: true,
        propertyToUpdate: "emailsSent",
        script: promotionalEmail(data),
      },
      {
        _id: uuidv4(),
        completed: false,
        date: today.toISOString(),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail:
          data.primaryContact && data.primaryContact === 1
            ? data.contactEmail || data.alternateContactEmail || data.email
            : data.email || "",
        contactNumber: "",
        contactPerson: "",
        description: `${data.contactName ? `Send Infopak & Cover Letter to ${data.contactName} (${data.contactJobTitle})` : ""}`,
        endTime: "",
        startTime: "",
        title: "Send Infopak & Cover Letter",
        union: data.union,
        clientType: "Prospect",
        completable: true,
        propertyToUpdate: "packagesSent",
        script: infopakLetter(data),
      },
      {
        _id: uuidv4(),
        completed: false,
        completable:true,
        date: nextContact.toISOString(),
        __v: 0,
        localNumber: data.localNumber,
        assignedRep: data.assignedRep,
        link: data._id,
        contactEmail:
          data.primaryContact && data.primaryContact === 1
            ? data.contactEmail || data.alternateContactEmail || data.email
            : data.email || "",
        contactNumber: "",
        contactPerson: "",
        description: `${data.contactName ? `Follow-up Phone Call with ${data.contactName ? data.contactName : "Client"} ${data.contactJobTitle && `(${data.contactJobTitle})`}` : ""}`,
        endTime: "",
        startTime: "",
        title: "Follow-up Phone Call",
        union: data.union,
        clientType: "Prospect",
        script: followUpCall(data),
      },
    ];
    increaseCount(data.assignedRep.toLowerCase(), "callsMade");
    axios.post(
      "https://unionapi.netlify.app/.netlify/functions/api/tasks",
      tasksToCreate,
    );
    addHistory({
      id: data._id,
      note: `Suspect became prospect`,
      reason: ``,
    });

    await refreshSuspectList();
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle any exceptions here
  }
}

async function getAllTasksAndDelete(taskSearchUrl) {
  try {
    // Make an HTTP GET request to retrieve all tasks
    const taskSearchResponse = await axios.get(taskSearchUrl);

    if (taskSearchResponse.status === 200) {
      // Extract the list of tasks from the response
      const tasks = taskSearchResponse.data;

      // Iterate through the tasks and delete each one
      for (const task of tasks) {
        // Check if the title includes 'Pre-Election'
        if (!task.title.includes('Pre-Election')) {
          // Construct the URL to delete the task
          const taskDeleteUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks/${task._id}`;

          // Make an HTTP DELETE request to delete the task
          const taskDeleteResponse = await axios.delete(taskDeleteUrl);

          if (taskDeleteResponse.status === 200) {
            console.log(
              `Task DELETE request successful for task ID: ${task._id}`,
            );
          } else {
            console.error(`Task DELETE request failed for task ID: ${task._id}`);
          }
        } else {
          console.log(`Task with title '${task.title}' is not deleted because it includes 'Pre-Election'.`);
        }
      }
    } else {
      console.error(
        "Task search GET request failed:",
        taskSearchResponse.status,
        taskSearchResponse.data,
      );
      // Handle error cases for the task search GET request
    }
  } catch (error) {
    console.error("An error occurred while deleting tasks:", error);
    // Handle any exceptions here
  }
}


async function deleteInitialCall({ union, localNumber }) {
  try {
    // Fetching the tasks
    const response = await axios.get(
      `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${union}&localNumber=${localNumber}`,
    );
    const tasks = response.data;

    // Find the task with the title "Make Initial Call"
    const initialCallTask = tasks.find(
      (task) => task.title === "Make Initial Call",
    );

    if (initialCallTask) {
      // If task is found, delete it using its _id
      const deleteResponse = await axios.delete(
        `https://unionapi.netlify.app/.netlify/functions/api/tasks/${initialCallTask._id}`,
      );
      console.log("Task deleted successfully", deleteResponse.data);
    } else {
      console.log('No task with the title "Make Initial Call" was found');
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
}

async function createNewTasks(
  unionName,
  localNumber,
  appointmentDate,
  id,
  data,
  startTime,
  endTime,
  notes,
) {
  try {
    // Make an Axios GET request to retrieve the assignedRep value
    const assignedRepResponse = await axios.get(
      `https://unionapi.netlify.app/.netlify/functions/api/tasks`,
      {
        params: {
          union: unionName,
          localNumber: localNumber,
        },
      },
    );

    // Check if the response data has the assignedRep value
    const assignedRep =
      assignedRepResponse.data[0]?.assignedRep || loggedInUser();

    // Parse the appointment date
    const appointment = new Date(appointmentDate);
    appointment.setHours(0, 0, 0, 0); // Ensure it's the start of the day
    addHistory({
      id: data._id,
      note: `Demo booked for ${formatDate(appointment)}`,
      reason: ``,
    });
    // Calculate the date 2 business days before the appointment
    const oneDayBefore = subtractBusinessDays(new Date(appointment), 1);

    // Calculate the confirmation date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Ensure it's the start of the day

    const confirmationDate = oneDayBefore >= today ? oneDayBefore : today;
    const letterDate = today >= appointment ? oneDayBefore : today;
    // Define the tasks data

    increaseCount(data.assignedRep.toLowerCase(), "demosBooked");
    const tasks = [
      {
        union: unionName,
        localNumber: localNumber,
        date: letterDate,
        assignedRep: assignedRep,
        link: id,
        completable: true,
        title: `Send Appointment Confirmation Letter ${unionName} ${localNumber}`,
        description: `Confirm they are available on ${formatDate(appointment)}`,
        clientType: "Activation",
        script: appointmentConfirmation({
          unionData: data,
          appointmentDate: appointment,
          startTime: startTime,
          endTime: endTime,
        }),
        contactEmail:
          data.primaryContact && data.primaryContact === 1
            ? data.contactEmail || data.alternateContactEmail || data.email
            : data.email || "",
        propertyToUpdate: "emailsSent",
      },
      {
        union: unionName,
        localNumber: localNumber,
        date: confirmationDate,
        link: id,
        completable: true,
        completed: false,
        assignedRep: assignedRep,
        title: `Call to confirm appointment with ${unionName} ${localNumber}`,
        description: `Make a confirmation call for appointment on ${formatDate(appointment)}`,
        clientType: "Activation",
        contactEmail:
          data.primaryContact && data.primaryContact === 1
            ? data.contactEmail || data.alternateContactEmail || data.email
            : data.email || "",
        script: confirmDemo({
          unionData: data,
          appointmentDate: appointment,
          startTime: startTime,
          endTime: endTime,
        }),
        propertyToUpdate: "callsMade",
      },
      {
        union: unionName,
        localNumber: localNumber,
        date: letterDate,
        link: id,
        completable: true,
        completed: false,
        assignedRep: assignedRep,
        title: `Pre-Demo Checklist`,
        description: `Send Leeanne a Pre-demo checklist`,
        clientType: "Activation",
        contactEmail:
          data.primaryContact && data.primaryContact === 1
            ? data.contactEmail || data.alternateContactEmail || data.email
            : data.email || "",
        script: preDemoLetter({
          unionData: data
          
        }),
        propertyToUpdate: "emailsSent",
      },
      {
        union: unionName,
        localNumber: localNumber,
        link: id,
        date: appointmentDate,
        assignedRep: assignedRep,
        title: `Demo with ${unionName} ${localNumber}`,
        description: notes,
        startTime,
        endTime,
        timeZone: data.timeZone,
        script: [],
        completed: false,
        clientType: "Activation",
      },

      {
        union: unionName,
        localNumber: localNumber,
        date: appointment,
        assignedRep: assignedRep,
        link: id,
        completable: true,
        title: `Send thank you letter to ${unionName} ${localNumber}`,
        description: `Send immediately following the appointment on  ${formatDate(appointment)}`,
        clientType: "Activation",
        script: thankYouLetter({ unionData: data }),
        contactEmail:
          data.primaryContact && data.primaryContact === 1
            ? data.contactEmail || data.alternateContactEmail || data.email
            : data.email || "",
        propertyToUpdate: "emailsSent",
      },
    ];

    // Create tasks
    for (const task of tasks) {
      const taskCreateResponse = await axios.post(
        "https://unionapi.netlify.app/.netlify/functions/api/tasks",
        task,
      );
      if (
        taskCreateResponse.status === 200 ||
        taskCreateResponse.status === 201
      ) {
        console.log("Task POST request successful:", taskCreateResponse.data);
      } else {
        console.error(
          "Task POST request failed:",
          taskCreateResponse.status,
          taskCreateResponse.data,
        );
      }
    }
  } catch (error) {
    console.error("An error occurred while creating new tasks:", error);
  }
}

export async function yesFunction(
  unionName,
  localNumber,
  id,
  appointmentDate,
  startTime,
  endTime,
  notes,
  refreshSuspectList,
  data,
) {
  const unionUrl = `https://unionapi.netlify.app/.netlify/functions/api/unions/${id}`;
  try {
    increaseCount(data.assignedRep.toLowerCase(), "callsMade");
    // Fetch the current union data
    const unionData = await fetchUnionData(id);

    await axios.put(unionUrl, { ...unionData, clientType: "Activation" });
    // Construct the URL to search for tasks with the same unionName and localNumber

    await getAllTasksAndDelete(
      `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${unionName}&localNumber=${localNumber}`,
    );
    const taskSearchUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${unionName}&localNumber=${localNumber}`;

    // Make an HTTP GET request to search for tasks
    const taskSearchResponse = await axios.get(taskSearchUrl);

    if (taskSearchResponse.status === 200) {
      // Assuming there's only one matching task, extract task data
      // const taskData = taskSearchResponse.data[0];

      // Fetch the current union data
      const unionDataResponse = await axios.get(
        `https://unionapi.netlify.app/.netlify/functions/api/unions/${id}`,
      );

      if (unionDataResponse.status === 200) {
        // Extract the current union data
        const unionData = unionDataResponse.data;

        // Update the .strategy property to 'appointment'
        unionData.strategy = "appointment";

        // Construct the URL to update the union data
        const unionUpdateUrl = `https://unionapi.netlify.app/.netlify/functions/api/unions/${id}`;

        // Make an HTTP PUT request to update the union data
        const unionPutResponse = await axios.put(unionUpdateUrl, unionData);

        if (unionPutResponse.status === 200) {
          console.log("Union PUT request successful:", unionPutResponse.data);

          // Call the createNewTasks function here to create new tasks
          await createNewTasks(
            unionName,
            localNumber,
            appointmentDate,
            id,
            data,
            startTime,
            endTime,
            notes,
          );

          // Call the refreshSuspectList function here
          refreshSuspectList();

          // Handle the response data if needed
        } else {
          console.error(
            "Union PUT request failed:",
            unionPutResponse.status,
            unionPutResponse.data,
          );
          // Handle error cases for the union PUT request
        }
      } else {
        console.error(
          "Union data GET request failed:",
          unionDataResponse.status,
          unionDataResponse.data,
        );
        // Handle error cases for the union data GET request
      }
    } else {
      console.error(
        "Task search GET request failed:",
        taskSearchResponse.status,
        taskSearchResponse.data,
      );
      // Handle error cases for the task search GET request
    }
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle any exceptions here
  }
}

async function createActiveApproachTask(data) {
  const today = new Date();
  const apiUrl = "https://unionapi.netlify.app/.netlify/functions/api/tasks";

  const activeApproachTasks = [
    {
      _id: uuidv4(),
      completed: false,
      date: today.toISOString(),
      __v: 0,
      localNumber: data.localNumber,
      assignedRep: data.assignedRep,
      link: data._id,
      description: "Send Who Letter",
      endTime: "",
      startTime: "",
      title: "Who Letter",
      union: data.union,
      clientType: data.clientType,
      completable: true,
      script: whoLetter(data),
      contactEmail:
        data.primaryContact && data.primaryContact === 1
          ? data.contactEmail || data.alternateContactEmail || data.email
          : data.email || "",
      contactNumber: "",
      contactPerson: "",
      propertyToUpdate: "emailsSent",
    },
    {
      _id: uuidv4(),
      completed: false,
      date: getNextBusinessDay(new Date(), 5),
      __v: 0,
      localNumber: data.localNumber,
      assignedRep: data.assignedRep,
      link: data._id,
      description: "Send What Letter",
      endTime: "",
      startTime: "",
      title: "Send What Letter",
      union: data.union,
      clientType: data.clientType,
      completable: true,
      script: whatLetter(data),
      contactEmail:
        data.primaryContact && data.primaryContact === 1
          ? data.contactEmail || data.alternateContactEmail || data.email
          : data.email || "",
      contactNumber: "",
      contactPerson: "",
      propertyToUpdate: "emailsSent",
    },
    {
      _id: uuidv4(),
      completed: false,
      date: getNextBusinessDay(new Date(), 10),
      __v: 0,
      localNumber: data.localNumber,
      assignedRep: data.assignedRep,
      link: data._id,
      description: "Send Will Call Letter",
      endTime: "",
      startTime: "",
      title: "Will Call Letter",
      union: data.union,
      clientType: data.clientType,
      completable: true,
      script: willCallLetter(data),
      contactEmail:
        data.primaryContact && data.primaryContact === 1
          ? data.contactEmail || data.alternateContactEmail || data.email
          : data.email || "",
      contactNumber: "",
      contactPerson: "",
      propertyToUpdate: "emailsSent",
    },
    {
      _id: uuidv4(),
      completed: false,
      date: getNextBusinessDay(new Date(), 12),
      __v: 0,
      localNumber: data.localNumber,
      assignedRep: data.assignedRep,
      link: data._id,
      contactNumber: "",
      contactPerson: "",
      description: `The introductory call`,
      endTime: "",
      startTime: "",
      title: "Make Initial Call",
      union: data.union,
      clientType: "Suspect",
      script: initialCall(data),
      contactEmail:
        data.primaryContact && data.primaryContact === 1
          ? data.contactEmail || data.alternateContactEmail || data.email
          : data.email || "",
    },
  ];

  addHistory({
    id: data._id,
    note: `Activated active approach`,
    reason: ``,
  });

  try {
    // Use Promise.all to send all tasks in parallel
    const responseArray = await Promise.all(
      activeApproachTasks.map((task) => axios.post(apiUrl, [task])),
    );

    // Log the responses
    responseArray.forEach((response, index) => {
      console.log(`Task ${index + 1} created successfully:`, response.data);
    });
  } catch (error) {
    console.error("An error occurred while creating the tasks:", error);
  }
}

export async function noContact({
  data,
  reason,
  note,
  nextContact,
  refreshSuspectList,
}) {
  const apiUrl = `https://unionapi.netlify.app/.netlify/functions/api/unions/${data._id}`;
  try {
    increaseCount(data.assignedRep.toLowerCase(), "callsMade");
    // Fetch the current union data
    const unionData = await fetchUnionData(data._id);

    // Check if the .noContact value is 3 or greater
    if (unionData.noContacts >= (data.strategy === "appointment" ? 6 : 3)) {
      console.warn(
        "Warning: .noContact value is 3 or greater. Not running other functions.",
      );

      await axios.put(apiUrl, {
        ...unionData,
        strategy: "active",
        noContacts: 0,
        clientType: "Suspect",
      });
      await getAllTasksAndDelete(
        `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${data.union}&localNumber=${data.localNumber}`,
      );
      await createActiveApproachTask(data);
      refreshSuspectList();
      return; // Exit the function without running other logic
    }

    // Update the union data and log the result
    const updatedUnionData = await updateUnionData(
      apiUrl,
      unionData,
      reason,
      note,
    );

    // Find the task and update it
    await deleteInitialCall({
      union: data.union,
      localNumber: data.localNumber,
    });

    //COMMENTED OUT RECENTLY
    await updateTask(
      data.union,
      data.localNumber,
      nextContact,
      updatedUnionData,
    );

    // Refresh the suspect list
    await refreshSuspectList();
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle any exceptions here
    console.log("error over here");
  }
}

async function fetchUnionData(id) {
  const apiUrl = `https://unionapi.netlify.app/.netlify/functions/api/unions/${id}`;
  const response = await axios.get(apiUrl);
  if (response.status !== 200) {
    throw new Error(`Failed to fetch union data. Status: ${response.status}`);
  }
  return response.data;
}

const reloadWindowAsync = async () => {
  window.location.reload(); // Reload the window
};

async function updateUnionData(apiUrl, unionData, reason, note) {
  //Move straight to active approach if wrong number
  if (note.includes("Called Wrong Number")) {
    await getAllTasksAndDelete(
      `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${unionData.union}&localNumber=${unionData.localNumber}`,
    );
    await createActiveApproachTask(unionData);

    //await deleteInitialCall(unionData.union, unionData.localNumber)

    await reloadWindowAsync();
    return;
  }

  const updatedNoContacts = (unionData.noContacts || 0) + 1;
  const newHistoryEntry = {
    note,
    reason,
    date: new Date().toISOString(),
  };

  const updatedUnionData = {
    ...unionData,
    noContacts: updatedNoContacts,
    history: [...(unionData.history || []), newHistoryEntry],
  };

  const putResponse = await axios.put(apiUrl, updatedUnionData);
  if (putResponse.status !== 200) {
    throw new Error(
      `Failed to update union data. Status: ${putResponse.status}`,
    );
  }

  console.log("Union data updated successfully:", putResponse.data);
  return updatedUnionData;
}

async function updateTask(
  unionName,
  localNumber,
  nextContact,
  updatedUnionData,
) {
  const taskSearchUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks?union=${unionName}&localNumber=${localNumber}`;
  const taskSearchResponse = await axios.get(taskSearchUrl);

  if (taskSearchResponse.status !== 200) {
    console.error(
      "Task search GET request failed:",
      taskSearchResponse.status,
      taskSearchResponse.data,
    );
    return;
  }

  //Don't add a new recontact task under conditions
  const existingTask = taskSearchResponse.data.find((task) =>
    task.title.includes("Recontact")||task.title.includes("Awaken")||task.title.includes("Initial"),
  );

  if (existingTask) {
    // If an existing task is found, update it using PUT
    const taskId = existingTask._id;
    const updatedTask = {
      date: nextContact,
      title: `Recontact ${updatedUnionData.noContacts}/${updatedUnionData.strategy === "appointment" ? "6" : "3"}`,
      completed: false,
      // Update other properties as needed
    };

    const taskUpdateUrl = `https://unionapi.netlify.app/.netlify/functions/api/tasks/${taskId}`;
    const taskPutResponse = await axios.put(taskUpdateUrl, updatedTask);

    if (taskPutResponse.status !== 200) {
      console.error(
        "Task PUT request failed:",
        taskPutResponse.status,
        taskPutResponse.data,
      );
      return;
    }

    console.log("Task updated successfully:", taskPutResponse.data);
  } else {
    // If no existing task is found, create a new task using POST
    const newTask = {
      union: unionName,
      localNumber: localNumber,
      date: nextContact,
      title: `Recontact ${updatedUnionData.noContacts}/${updatedUnionData.strategy === "appointment" ? "6" : "3"}`,
      completed: false,
      completable: true,
      _id: uuidv4(),
      clientType: updatedUnionData.clientType,
      __v: 0,
      link: updatedUnionData._id,
      description: `Recontact`,
      assignedRep: updatedUnionData.assignedRep,
      script: initialCall(updateUnionData),

      // Set other properties for a new task
    };

    const taskCreateUrl =
      "https://unionapi.netlify.app/.netlify/functions/api/tasks";
    const taskPostResponse = await axios.post(taskCreateUrl, newTask);

    if (taskPostResponse.status !== 201) {
      console.error(
        "Task POST request failed:",
        taskPostResponse.status,
        taskPostResponse.data,
      );
      return;
    }

    console.log("New task created successfully:", taskPostResponse.data);
  }
}
